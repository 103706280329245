@import '../../shared/constants/breakpoints';

.payment {
  @include media-sm {
    height: 100dvh;
  }
  display: flex;

  box-sizing: border-box;
  width: 100%;
  max-width: 585px;

  &-body {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 60px;
    align-items: center;

    box-sizing: border-box;
    width: 100%;
    &_methods {
      display: flex;
      gap: 10px;
    }
    .header {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-s);

      font-weight: var(--font-weight-350);
      text-align: center;
      &_title {
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: space-between;

        p {
          margin: 0;
        }

        .subtitle:hover {
          cursor: pointer;
        }
      }
    }

    .payment-form {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-l);

      max-width: 385px;
      height: 100%;

      &_header {
        display: flex;
        align-items: end;
        justify-content: space-between;

        p {
          margin: 0;
        }

        .subtitle:hover {
          cursor: pointer;
        }
      }
    }
  }
}
