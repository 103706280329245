/***Do not edit directly
 * Generated on Mon, 23 Dec 2024 09:39:06 GMT*/

[data-theme-mode="light"] {
  --region-danger-lowest-contrast: #4f1625;
  --region-danger-low-contrast: #d11c36;
  --region-danger-high-contrast: #f1364d;
  --region-danger-contrast: #dc1d39;
  --region-danger-on-accent: #fff;
  --region-surface-popover: #1b1d2e;
  --region-surface-low-contrast: #fff;
  --region-surface-on-surface-contrast-lowest: #0000001a;
  --region-surface-on-surface-contrast-low: #0000004d;
  --region-surface-on-surface-contrast-default: #00000080;
  --region-surface-on-surface-contrast-high: #000000b3;
  --region-surface-on-surface-contrast-highest: #000;
  --region-surface-default: #e8e8ec;
  --region-accent-on-accent: #fff;
  --region-accent-low-contrast: #18b130;
  --region-accent-high-contrast: #27c440;
  --region-accent-contrast: #20b938;
  --theme-grey-900: #12121c;
  --theme-grey-800: #151722;
  --theme-grey-700: #1b1d2e;
  --theme-grey-600: #232538;
  --theme-grey-500: #27293e;
  --theme-grey-400: #525465;
  --theme-grey-300: #6e707e;
  --theme-grey-200: #9597a6;
  --theme-grey-100: #c9c9d4;
  --theme-grey-50: #e8e8ec;
  --theme-grey-25: #f3f3f7;
  --theme-grey-0: #fff;
  --theme-violet-deep-600: #110a27;
  --theme-violet-deep-500: #191138;
  --theme-violet-deep-400: #1c143a;
  --theme-violet-deep-300: #494361;
  --theme-violet-deep-200: #6e6887;
  --theme-violet-deep-100: #a097bf;
  --theme-violet-deep-50: #e4e4f0;
  --theme-violet-deep-0: #fff;
  --theme-orange-pale-900: #633219;
  --theme-orange-pale-800: #824220;
  --theme-orange-pale-700: #a8552a;
  --theme-orange-pale-600: #d76d36;
  --theme-orange-pale-500: #ec783b;
  --theme-orange-pale-400: #f38125;
  --theme-orange-pale-300: #f59a51;
  --theme-orange-pale-200: #f7ab6d;
  --theme-orange-pale-100: #f9c59b;
  --theme-orange-pale-50: #fbd8bb;
  --theme-orange-pale-25: #fef2e9;
  --theme-purple-darker-900: #306;
  --theme-purple-darker-800: #430085;
  --theme-purple-darker-700: #5700ac;
  --theme-purple-darker-600: #7a00f2;
  --theme-purple-darker-500: #8101ff;
  --theme-purple-darker-400: #860dff;
  --theme-purple-darker-300: #9e3dff;
  --theme-purple-darker-200: #ae5dff;
  --theme-purple-darker-100: #c790ff;
  --theme-purple-darker-50: #d9b4ff;
  --theme-purple-darker-25: #f3e7ff;
  --theme-green-yellow-900: #475d1e;
  --theme-green-yellow-800: #5c7a27;
  --theme-green-yellow-700: #779d32;
  --theme-green-yellow-600: #99c941;
  --theme-green-yellow-500: #a8dd47;
  --theme-green-yellow-400: #b9e46c;
  --theme-green-yellow-300: #c5e884;
  --theme-green-yellow-200: #d7efaa;
  --theme-green-yellow-100: #e4f4c6;
  --theme-green-yellow-50: #f6fced;
  --theme-red-saturated-900: #6b0000;
  --theme-red-saturated-800: #8c0000;
  --theme-red-saturated-700: #b50000;
  --theme-red-saturated-600: #e80000;
  --theme-red-saturated-500: #f00;
  --theme-red-saturated-400: #f33;
  --theme-red-saturated-300: #ff5454;
  --theme-red-saturated-200: #ff8a8a;
  --theme-red-saturated-100: #ffb0b0;
  --theme-red-saturated-50: #ffe6e6;
  --theme-red-warm-900: #5f1a15;
  --theme-red-warm-800: #7c221c;
  --theme-red-warm-700: #a02c24;
  --theme-red-warm-600: #cd382e;
  --theme-red-warm-500: #e13e33;
  --theme-red-warm-400: #e7655c;
  --theme-red-warm-300: #eb7e76;
  --theme-red-warm-200: #f1a6a1;
  --theme-red-warm-100: #f6c3c0;
  --theme-red-warm-50: #fceceb;
  --theme-violet-deep-light-to-dark-600: #110a27;
  --theme-violet-deep-light-to-dark-500: #191138;
  --theme-violet-deep-light-to-dark-400: #1c143a;
  --theme-violet-deep-light-to-dark-300: #494361;
  --theme-violet-deep-light-to-dark-200: #6e6887;
  --theme-violet-deep-light-to-dark-100: #a097bf;
  --theme-violet-deep-light-to-dark-50: #e4e4f0;
  --theme-violet-deep-light-to-dark-0: #fff;
  --theme-black-to-white-alpha-100: #000;
  --theme-black-to-white-alpha-90: #000000e6;
  --theme-black-to-white-alpha-80: #000c;
  --theme-black-to-white-alpha-70: #000000b3;
  --theme-black-to-white-alpha-60: #0009;
  --theme-black-to-white-alpha-50: #00000080;
  --theme-black-to-white-alpha-40: #0006;
  --theme-black-to-white-alpha-30: #0000004d;
  --theme-black-to-white-alpha-20: #0003;
  --theme-black-to-white-alpha-10: #0000001a;
  --theme-black-to-white-alpha-5: #0000000d;
  --theme-white-to-black-alpha-100: #fff;
  --theme-white-to-black-alpha-90: #ffffffe6;
  --theme-white-to-black-alpha-80: #fffc;
  --theme-white-to-black-alpha-70: #ffffffb3;
  --theme-white-to-black-alpha-60: #fff9;
  --theme-white-to-black-alpha-50: #ffffff80;
  --theme-white-to-black-alpha-40: #fff6;
  --theme-white-to-black-alpha-30: #ffffff4d;
  --theme-white-to-black-alpha-20: #fff3;
  --theme-white-to-black-alpha-10: #ffffff1a;
  --theme-white-to-black-alpha-5: #ffffff0d;
  --theme-pink-900: #661d48;
  --theme-pink-800: #86265f;
  --theme-pink-700: #ad317a;
  --theme-pink-600: #dd3f9d;
  --theme-pink-500: #f345ac;
  --theme-pink-400: #f56abd;
  --theme-pink-300: #f782c7;
  --theme-pink-200: #f9a9d9;
  --theme-pink-100: #fbc5e5;
  --theme-pink-50: #feecf7;
  --theme-green-900: #0a4a14;
  --theme-green-800: #0d611a;
  --theme-green-700: #117e22;
  --theme-green-600: #18b130;
  --theme-green-500: #20b938;
  --theme-green-400: #27c440;
  --theme-green-300: #52d066;
  --theme-green-200: #6ed77f;
  --theme-green-100: #9ce4a7;
  --theme-green-50: #bcedc4;
  --theme-green-25: #e9f9ec;
  --theme-red-cold-900: #4f1625;
  --theme-red-cold-800: #6b0000;
  --theme-red-cold-700: #8c0000;
  --theme-red-cold-600: #b50000;
  --theme-red-cold-500: #d11c36;
  --theme-red-cold-400: #dc1d39;
  --theme-red-cold-300: #f1364d;
  --theme-red-cold-200: #e8687a;
  --theme-red-cold-100: #ef97a4;
  --theme-red-cold-50: #f4b9c2;
  --theme-red-cold-25: #fce8eb;
  --theme-green-warm-900: #0c410b;
  --theme-green-warm-800: #10550e;
  --theme-green-warm-700: #156d12;
  --theme-green-warm-600: #1a8c17;
  --theme-green-warm-500: #1d9a19;
  --theme-green-warm-400: #59be21;
  --theme-green-warm-300: #7acb4d;
  --theme-green-warm-200: #90d36a;
  --theme-green-warm-100: #b3e199;
  --theme-green-warm-50: #ccebba;
  --theme-green-warm-25: #eef9e9;
  --theme-grey-light-to-dark-900: #12121c;
  --theme-grey-light-to-dark-800: #151722;
  --theme-grey-light-to-dark-700: #1b1d2e;
  --theme-grey-light-to-dark-600: #232538;
  --theme-grey-light-to-dark-500: #27293e;
  --theme-grey-light-to-dark-400: #525465;
  --theme-grey-light-to-dark-300: #6e707e;
  --theme-grey-light-to-dark-200: #9597a6;
  --theme-grey-light-to-dark-100: #c9c9d4;
  --theme-grey-light-to-dark-50: #e8e8ec;
  --theme-grey-light-to-dark-25: #f3f3f7;
  --theme-grey-light-to-dark-0: #fff;
  --theme-purple-900: #3f1d50;
  --theme-purple-800: #522569;
  --theme-purple-700: #6a3088;
  --theme-purple-600: #883eae;
  --theme-purple-500: #9544bf;
  --theme-purple-400: #aa69cc;
  --theme-purple-300: #b882d4;
  --theme-purple-200: #cea9e2;
  --theme-purple-100: #dec5eb;
  --theme-purple-50: #f4ecf9;
  --theme-violet-900: #26184c;
  --theme-violet-800: #321f64;
  --theme-violet-700: #412881;
  --theme-violet-600: #5334a6;
  --theme-violet-500: #5b39b6;
  --theme-violet-400: #7c61c5;
  --theme-violet-300: #917ace;
  --theme-violet-200: #b4a4dd;
  --theme-violet-100: #ccc2e8;
  --theme-violet-50: #efebf8;
  --theme-blue-light-900: #124657;
  --theme-blue-light-800: #175c72;
  --theme-blue-light-700: #1e7794;
  --theme-blue-light-600: #2698bd;
  --theme-blue-light-500: #2aa7d0;
  --theme-blue-light-400: #55b9d9;
  --theme-blue-light-300: #70c4e0;
  --theme-blue-light-200: #9dd7e9;
  --theme-blue-light-100: #bde4f0;
  --theme-blue-light-50: #eaf6fa;
  --theme-blue-darkish-900: #19154f;
  --theme-blue-darkish-800: #211c67;
  --theme-blue-darkish-700: #2b2485;
  --theme-blue-darkish-600: #372eaa;
  --theme-blue-darkish-500: #3c33bb;
  --theme-blue-darkish-400: #635cc9;
  --theme-blue-darkish-300: #7c76d1;
  --theme-blue-darkish-200: #a5a1e0;
  --theme-blue-darkish-100: #c3c0ea;
  --theme-blue-darkish-50: #ecebf8;
  --theme-blue-900: #0d3555;
  --theme-blue-800: #114570;
  --theme-blue-700: #155990;
  --theme-blue-600: #1b72b9;
  --theme-blue-500: #1e7dcb;
  --theme-blue-400: #4b97d5;
  --theme-blue-300: #68a8dc;
  --theme-blue-200: #98c3e7;
  --theme-blue-100: #b9d7ef;
  --theme-blue-50: #e9f2fa;
  --theme-orange-900: #682b0f;
  --theme-orange-800: #883913;
  --theme-orange-700: #af4919;
  --theme-orange-600: #e15e20;
  --theme-orange-500: #f46623;
  --theme-orange-400: #f76723;
  --theme-orange-300: #f9854f;
  --theme-orange-200: #fa996c;
  --theme-orange-100: #fbb99a;
  --theme-orange-50: #fdd0bb;
  --theme-orange-25: #fef0e9;
  --theme-yellow-950: #5c4107;
  --theme-yellow-900: #785509;
  --theme-yellow-800: #9b6d0b;
  --theme-yellow-700: #c68c0f;
  --theme-yellow-600: #da9a10;
  --theme-yellow-500: #f9c10b;
  --theme-yellow-400: #fdca44;
  --theme-yellow-300: #fdd569;
  --theme-yellow-200: #fedb82;
  --theme-yellow-100: #fee7a9;
  --theme-yellow-50: #feefc5;
  --theme-yellow-25: #fffaec;
  --theme-black-alpha-100: #000;
  --theme-black-alpha-90: #000000e6;
  --theme-black-alpha-80: #000c;
  --theme-black-alpha-70: #000000b3;
  --theme-black-alpha-60: #0009;
  --theme-black-alpha-50: #00000080;
  --theme-black-alpha-40: #0006;
  --theme-black-alpha-30: #0000004d;
  --theme-black-alpha-20: #0003;
  --theme-black-alpha-10: #0000001a;
  --theme-black-alpha-5: #0000000d;
  --theme-white-alpha-100: #fff;
  --theme-white-alpha-90: #ffffffe6;
  --theme-white-alpha-80: #fffc;
  --theme-white-alpha-70: #ffffffb3;
  --theme-white-alpha-60: #fff9;
  --theme-white-alpha-50: #ffffff80;
  --theme-white-alpha-40: #fff6;
  --theme-white-alpha-30: #ffffff4d;
  --theme-white-alpha-20: #fff3;
  --theme-white-alpha-10: #ffffff1a;
  --theme-white-alpha-5: #ffffff0d;
}
