$screen-sm: 500px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;

@mixin media-sm {
  @media (max-width: $screen-sm) {
    @content;
  }
}

@mixin media-md {
  @media (min-width: $screen-md) {
    @content;
  }
}

@mixin media-lg {
  @media (min-width: $screen-lg) {
    @content;
  }
}

@mixin media-xl {
  @media (min-width: $screen-xl) {
    @content;
  }
}
