@font-face {
  font-family: Gotham-SSm;
  font-weight: 900;
  font-display: block;
  src:
    local('GothamSSm Black'),
    local('GothamSSm-Black'),
    url('GothamSSm-Black.otf') format('truetype');
}
@font-face {
  font-family: Gotham-SSm;
  font-weight: 900;
  font-style: italic;
  font-display: block;
  src:
    local('GothamSSm Black Italic'),
    local('GothamSSm-BlackItalic'),
    url('GothamSSm-BlackItalic.otf') format('truetype');
}

@font-face {
  font-family: Gotham-SSm;
  font-weight: 700;
  font-display: block;
  src:
    local('GothamSSm Bold'),
    local('GothamSSm-Bold'),
    url('GothamSSm-Bold.otf') format('truetype');
}
@font-face {
  font-family: Gotham-SSm;
  font-weight: 700;
  font-style: italic;
  font-display: block;
  src:
    local('GothamSSm Bold Italic'),
    local('GothamSSm-BoldItalic'),
    url('GothamSSm-BoldItalic.otf') format('truetype');
}

@font-face {
  font-family: Gotham-SSm;
  font-weight: 700;
  font-display: block;
  src:
    local('GothamSSm Bold'),
    local('GothamSSm-Bold'),
    url('GothamSSm-Bold.otf') format('truetype');
}
@font-face {
  font-family: Gotham-SSm;
  font-weight: 700;
  font-style: italic;
  font-display: block;
  src:
    local('GothamSSm Bold Italic'),
    local('GothamSSm-BoldItalic'),
    url('GothamSSm-BoldItalic.otf') format('truetype');
}

@font-face {
  font-family: Gotham-SSm;
  font-weight: 500;
  font-display: block;
  src:
    local('GothamSSm Medium'),
    local('GothamSSm-Medium'),
    url('GothamSSm-Medium.otf') format('truetype');
}
@font-face {
  font-family: Gotham-SSm;
  font-weight: 500;
  font-style: italic;
  font-display: block;
  src:
    local('GothamSSm Medium Italic'),
    local('GothamSSm-MediumItalic'),
    url('GothamSSm-MediumItalic.otf') format('truetype');
}

@font-face {
  font-family: Gotham-SSm;
  font-weight: 350;
  font-display: block;
  src:
    local('GothamSSm Book'),
    local('GothamSSm-Book'),
    url('GothamSSm-Book.otf') format('truetype');
}
@font-face {
  font-family: Gotham-SSm;
  font-weight: 350;
  font-style: italic;
  font-display: block;
  src:
    local('GothamSSm Book Italic'),
    local('GothamSSm-Book'),
    url('GothamSSm-BookItalic.otf') format('truetype');
}

@font-face {
  font-family: Gotham-SSm;
  font-weight: 300;
  font-display: block;
  src:
    local('GothamSSm Light'),
    local('GothamSSm-Light'),
    url('GothamSSm-Light.otf') format('truetype');
}
@font-face {
  font-family: Gotham-SSm;
  font-weight: 300;
  font-style: italic;
  font-display: block;
  src:
    local('GothamSSm Light Italic'),
    local('GothamSSm-LightItalic'),
    url('GothamSSm-LightItalic.otf') format('truetype');
}

@font-face {
  font-family: Gotham-SSm;
  font-weight: 100;
  font-display: block;
  src:
    local('GothamSSm Thin'),
    local('GothamSSm-Thin'),
    url('GothamSSm-XLight.otf') format('truetype');
}
@font-face {
  font-family: GothamSSm;
  font-weight: 100;
  font-style: italic;
  font-display: block;
  src:
    local('GothamSSm Thin Italic'),
    local('GothamSSm-ThinItalic'),
    url('GothamSSm-XLightItalic.otf') format('truetype');
}
