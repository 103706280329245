@import '../../shared/constants/breakpoints';

.container {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-s, 10px);

  width: 100%;
}

.card-section-top-wrapper {
  scrollbar-width: none;

  overflow-x: auto;

  width: 100vw;
  max-width: 100%;

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.card-section-top {
  @include media-sm {
    padding: 0 16px;
  }
  display: flex;
  gap: 10px;

  min-width: 380px;
  padding: 0;
}

.saved-card {
  width: 120px;
  min-width: 120px;
  max-height: 74px;
}

.button-choose-card-amount {
  cursor: pointer;

  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;

  padding: 0 16px;

  font-weight: var(--font-weight-350);
}

.card-section-bottom {
  overflow: hidden auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  max-height: calc(100% - var(--card-section-bottom));
  margin-top: var(--card-section-bottom);
  padding-bottom: var(--card-section-bottom);

  &::-webkit-scrollbar {
    width: 8px;
  }

  /*Track*/
  &::-webkit-scrollbar-track {
    background: var(--region-surface-on-surface-contrast-lowest);
    border-radius: 10px;
  }

  /*Handle*/
  &::-webkit-scrollbar-thumb {
    background: var(--region-surface-on-surface-contrast-default);
    border-radius: 10px;
  }

  &-empty-space {
    height: 30px;
  }

  &-container {
    --card-section-bottom: 30px;
    position: absolute;
    z-index: 1;
    top: 100%;

    width: 100%;

    background-color: var(--region-surface-default);
  }
}
.saved-card-bottom {
  max-height: 74px;
}

.new-card-icon {
  & path {
    fill: var(--region-surface-on-surface-contrast-default);
  }
  &_active {
    & path {
      fill: var(--region-surface-on-surface-contrast-highest);
    }
  }
}

.hidden-cards-icon {
  & path {
    fill: var(--region-surface-on-surface-contrast-default);
  }
  &_active {
    & path {
      fill: var(--region-surface-on-surface-contrast-highest);
    }
  }
}
