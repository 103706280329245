@import './shared/constants/breakpoints';
@import './shared/constants/css-tokens/light-theme.css';
@import './shared/constants/css-tokens/dark-theme.css';
@import './shared/constants/css-tokens/spacing.css';

:root {
  --font-size-10: 10px;
  --font-size-11: 11px;
  --font-size-12: 12px;
  --font-size-13: 13px;
  --font-size-14: 14px;
  --font-size-15: 15px;
  --font-size-20: 20px;
  --font-size-21: 21px;
  --font-size-24: 24px;
  --font-size-35: 35px;
  --line-height-45: 45px;
  --line-height-25: 25px;
  --line-height-20: 20px;
  --line-height-15: 15px;
  --letter-spacing-micro: -1px;
  --letter-spacing-mini: -0.5px;
  --letter-spacing-small: -0.3px;
  --letter-spacing-zero: 0;
  --letter-spacing-middle: 0.4px;
  --letter-spacing-medium: 1.3px;
  --letter-spacing-large: 1px;
  --font-weight-350: 350;
  --font-weight-500: 500;
  --border-radius-5: 5px;
  --border-radius-3: 3px;
  --border-radius-2: 2px;
  --font-family: 'Gotham-SSm';
  --screen-sm: #{$screen-sm};
  --screen-md: #{$screen-md};
  --screen-lg: #{$screen-lg};
  --screen-xl: #{$screen-xl};
}
