@import '../../constants/breakpoints';

.payment {
  &-body__form {
    @include media-sm {
      gap: 0;
      justify-content: space-between;
    }
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
    justify-content: flex-start;

    height: 100%;

    .fields-wrapper {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-xl);
    }
  }

  &-button {
    width: 100%;
  }

  &-loader {
    margin: 0 auto;
  }
}
