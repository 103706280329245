.button {
  cursor: pointer;

  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;

  font-family: var(--font-family);
  font-weight: 350;
  color: var(--region-accent-on-accent);
  letter-spacing: var(--letter-spacing-small);

  border-radius: var(--border-radius-3);

  &:disabled {
    cursor: default;
  }

  &_fullwidth {
    min-width: 100%;
  }

  &_fill {
    background-color: var(--region-accent-contrast);
    border: none;

    &:focus {
      background-color: var(--region-accent-high-contrast);
      border-radius: 4px; // если прописать 2px, то закругления совсем не видно
      outline: 1px solid var(--theme-white-alpha-100);
      outline-offset: -3px;
    }

    &:hover {
      background-color: var(--region-accent-high-contrast);
    }

    &:active {
      background-color: var(--region-accent-low-contrast);
    }

    &:disabled {
      color: var(--region-surface-on-surface-contrast-low);

      background-color: var(--region-surface-on-surface-contrast-lowest);
    }

    &_danger {
      color: var(--region-danger-on-accent);

      background-color: var(--region-danger-contrast);

      &:focus {
        background-color: var(--region-danger-high-contrast);
        border-radius: 4px; // если прописать 2px, то закругления совсем не видно
        outline: 1px solid var(--region-danger-on-accent);
        outline-offset: -3px;
      }

      &:hover {
        background-color: var(--region-danger-high-contrast);
      }

      &:active {
        background-color: var(--region-danger-low-contrast);
      }

      &:disabled {
        color: var(--region-surface-on-surface-contrast-low);

        background-color: var(--region-surface-on-surface-contrast-lowest);
      }
    }
  }

  &_outline {
    color: var(--region-surface-on-surface-contrast-highest);

    background-color: transparent;
    border: 1px solid var(--region-surface-on-surface-contrast-low);

    &:focus {
      border: 1px solid var(--region-accent-high-contrast);
    }

    &:hover {
      border: 1px solid var(--region-surface-on-surface-contrast-highest);
    }

    &:active {
      border: 1px solid var(--region-surface-on-surface-contrast-highest);
    }

    &:disabled {
      color: var(--region-surface-on-surface-contrast-low);

      border: 1px solid var(--region-surface-on-surface-contrast-low);
    }

    &_danger {
      color: var(--region-surface-on-surface-contrast-highest);

      border: 1px solid var(--region-danger-lowest-contrast);

      &:focus {
        border: 1px solid var(--region-danger-high-contrast);
      }

      &:hover {
        border: 1px solid var(--region-danger-high-contrast);
      }

      &:active {
        border: 1px solid var(--region-danger-low-contrast);
      }

      &:disabled {
        color: var(--region-surface-on-surface-contrast-low);

        border: 1px solid var(--region-surface-on-surface-contrast-low);
      }
    }
  }

  &_standart {
    width: fit-content;
    padding: 7px 30px 8px;

    font-size: var(--font-size-15);
    line-height: var(--line-height-25);
  }

  &_small {
    width: fit-content;
    padding: 5px 15px;

    font-size: var(--font-size-11);
    line-height: var(--line-height-15);
  }
}
