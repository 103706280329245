.container {
  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--region-surface-on-surface-contrast-lowest);
  border-radius: var(--border-radius-5);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 10px 20px;

  font-weight: var(--font-weight-350);
  color: var(--region-surface-on-surface-contrast-highest);

  &_active {
    color: var(--surface-on-primary-alpha-lowest-variant);
  }

  & .ellipse-icon {
    & circle {
      fill: var(--region-surface-on-surface-contrast-highest);
    }

    &_active {
      color: var(--surface-on-primary-alpha-lowest-variant);
    }
  }
}

.info-slot {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.info-slot-left {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.info-slot-left-card-info {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;

  width: 80px;
}

.info-slot-left-system {
  min-width: 36px;
}

.info-slot-right-delete {
  cursor: pointer;

  width: 15px;
  height: 15px;
}

.card-icon {
  &_mir,
  &_mastercard {
    & path {
      fill: var(--region-surface-on-surface-contrast-highest);
    }
  }
  &_visa {
    & > g > g {
      & path {
        fill: var(--region-surface-on-surface-contrast-highest);
      }
    }
  }

  &_active {
    &_mir,
    &_mastercard {
      & path {
        fill: var(--surface-on-primary-alpha-lowest-variant);
      }
    }
    &_visa {
      & > g > g {
        & path {
          fill: var(--surface-on-primary-alpha-lowest-variant);
        }
      }
    }
  }
}
