.management {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  box-sizing: content-box;
  width: 100%;
  height: 100vh;

  &_loading {
    grid-template-columns: 1fr;
  }

  &_blackout {
    position: absolute;
    z-index: 10;
    inset: 0;

    background: var(--theme-black-alpha-40);
  }
}

.body {
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
  gap: 5px;

  box-sizing: border-box;
  height: 100%;

  &::-webkit-scrollbar {
    width: 8px;
  }

  /*Track*/
  &::-webkit-scrollbar-track {
    background: var(--region-surface-on-surface-contrast-lowest);
    border-radius: 10px;
  }

  /*Handle*/
  &::-webkit-scrollbar-thumb {
    background: var(--region-surface-on-surface-contrast-default);
    border-radius: 10px;
  }

  .no-cards-wrapper {
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: center;
    justify-content: center;

    height: 100%;
    margin-bottom: 120px;

    .typography {
      margin: 0;

      text-align: center;
    }
  }
}

.cards {
  width: 100%;
  height: 45px;
}

.confirm-delete {
  position: absolute;
  z-index: 100;
  bottom: 0;

  width: 100%;
  margin: -20px -15px;
  padding: 20px 15px;

  background: var(--region-surface-default);
  border-radius: 8px 8px 0 0;

  .title {
    margin: 0 0 5px;
  }

  .subtitle {
    margin: 0 0 21px;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty-card-icon {
  & path {
    fill: var(--region-accent-contrast);
  }
}
