.payment {
  display: flex;
  flex-direction: column;
  gap: 60px;

  box-sizing: border-box;
  width: 585px;
  height: 727px;

  &-header {
    position: relative;

    display: flex;
    flex-direction: column;
    gap: var(--spacing-s, 10px);
    align-items: center;

    width: 100%;

    &-text {
      margin: 0;
    }
  }

  &-body {
    position: relative;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    align-items: center;
    justify-content: center;

    width: 100%;

    .loader {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-left: 240px;
    }

    .method-wrapper {
      display: flex;
      justify-content: center;

      .method-item {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 160px;
        max-height: 40px;

        border-radius: 3px;
      }
    }
  }
}
