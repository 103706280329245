@import '@/shared/constants/breakpoints';

.input-wrapper {
  height: 60px;
}

.form-wrapper {

  @include media-sm {
    padding: 0 16px 16px;
  }
  height: 100%;

  &.hidden {
    display: none;
  }
}

.error-button {
  margin: 0 auto;
  margin-top: 8px;

  &-wrapper {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
