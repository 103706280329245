.container {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  min-width: 120px;

  background: var(--region-surface-on-surface-contrast-lowest);
  border-radius: var(--border-radius-3);

  &:hover {
    background: var(--region-surface-on-surface-contrast-low);
  }

  &_active {
    background: var(--theme-white-alpha-100);
    &:hover {
      background: var(--region-surface-on-surface-contrast-low);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 10px;

  font-weight: var(--font-weight-350);
}

.top-slot {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;

  width: 100%;
  height: 25px;
}
.ellipses {
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 100%;

  & svg {
    & circle {
      fill: var(--region-surface-on-surface-contrast-default);
    }
  }

  &_active {
    & svg {
      & circle {
        fill: var(--theme-black-alpha-70);
      }
    }
  }
}
.number {
  font-size: var(--font-size-13);
  font-weight: var(--font-weight-350);
  color: var(--region-surface-on-surface-contrast-default);

  &_active {
    color: var(--theme-black-alpha-70);
  }
}

.bottom-slot {
  width: 100%;
  height: 28px;

  &_mir,
  &_mastercard {
    & svg {
      & path {
        fill: var(--region-surface-on-surface-contrast-default);
      }
    }
  }
  &_visa {
    & svg > g > g {
      & path {
        fill: var(--region-surface-on-surface-contrast-default);
      }
    }
  }

  &_active {
    &_mir,
    &_mastercard {
      & svg {
        & path {
          fill: var(--theme-black-alpha-70);
        }
      }
    }
    &_visa {
      & svg > g > g {
        & path {
          fill: var(--theme-black-alpha-70);
        }
      }
    }
  }
}
