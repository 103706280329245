.container {
  cursor: pointer;

  box-sizing: border-box;
  width: 100%;
  padding: 10px;

  color: var(--region-surface-on-surface-contrast-low);

  border: 1px solid var(--region-surface-on-surface-contrast-low);
  border-radius: var(--border-radius-3);

  &:hover {
    border: 1px solid var(--region-surface-on-surface-contrast-default);
  }

  &_active {
    color: var(--region-surface-on-surface-contrast-highest);

    // background-color: var(--region-surface-on-surface-contrast-high);
    border-color: var(--region-surface-on-surface-contrast-highest);
  }

  .content {
    font-size: var(--font-size-12);
    font-weight: var(--font-weight-500);
    line-height: var(--line-height-15);
    letter-spacing: var(--letter-spacing-middle);
  }
}
