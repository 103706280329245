.checkbox-container {
  cursor: pointer;

  display: flex;
  gap: 10px;
  align-items: center;
}
.checkbox {
  cursor: pointer;

  width: 15px;
  height: 15px;

  border: 1px solid var(--region-surface-on-surface-contrast-low);
  border-radius: var(--border-radius-2);

  appearance: none;
  &:hover {
    border: 1px solid var(--region-surface-on-surface-contrast-highest);
  }
  &:focus {
    border: 1px solid var(--region-accent-high-contrast);
  }
  &:disabled {
    background-color: var(--region-surface-on-surface-contrast-lowest);
    border: none;
  }
}
.checkbox-label {
  font-size: var(--font-size-13);
  font-weight: var(--font-weight-350);
  color: var(--region-surface-on-surface-contrast-highest);
  &_11 {
    font-size: var(--font-size-11);
    line-height: var(--line-height-15);
    &_disabled {
      color: var(--region-surface-on-surface-contrast-default);
    }
  }
  &_13 {
    font-size: var(--font-size-13);
    line-height: var(--line-height-20);
    &_disabled {
      color: var(--region-surface-on-surface-contrast-default);
    }
  }
}
.checkbox:checked {
  color: var(--region-surface-on-surface-contrast-highest);

  background-color: var(--region-accent-contrast);

  &::after {
    content: '';

    display: flex;
    flex-shrink: 0;

    width: 100%;
    height: 100%;

    background: url('../../icons/checkbox-arrow.svg') center center no-repeat;
  }

  &:disabled {
    background-color: var(--region-surface-on-surface-contrast-lowest);
    border: none;
  }
}
