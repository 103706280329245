.payment {
  overflow: auto;
  display: flex;

  width: 100%;
  max-width: 585px;
  height: 337px;
}

.payment-body {
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 0 16px;
}

.header {
  font-weight: var(--font-weight-350);
  text-align: center;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 30px;

  width: 100%;
  max-width: 385px;
}

.top-section-body {
  display: flex;
  flex-direction: column;
  gap: 15px;

  font-weight: var(--font-weight-350);
}

.payment-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.card-info {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: last baseline;
}

.card-number-info {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.middle-section-body {
  font-weight: var(--font-weight-350);
}

.button-confirm {
  width: 100%;
}

.iframe-wrapper {
  pointer-events: none;

  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  & > * {
    pointer-events: all;
  }
}

.ellipse-icon {
  & circle {
    fill: var(--region-surface-on-surface-contrast-highest);
  }
}
