/***Do not edit directly
 * Generated on Mon, 23 Dec 2024 09:39:07 GMT*/

:root {
  --spacing-5xl: 100px;
  --spacing-4xl: 80px;
  --spacing-3xl: 60px;
  --spacing-2xl: 45px;
  --spacing-xl: 30px;
  --spacing-l: 20px;
  --spacing-m: 15px;
  --spacing-s: 10px;
  --spacing-xs: 5px;
}
