.timer {
  display: flex;
  gap: 4px;

  margin: 0 auto;

  font-size: var(--font-size-13);
  font-weight: var(--font-weight-350);
  color: var(--region-surface-on-surface-contrast-default);

  &-time {
    width: 43px;
  }
}
