.layout {
  display: flex;
  flex-direction: column;
  gap: 20px;

  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px 15px;

  .buttons-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .title {
    font-size: 21px;
    font-weight: 500;
    font-style: normal;
    line-height: 25px;
    color: var(--surface-on-primary);
  }
  .content {
    position: relative;

    height: 100%;
  }
}
