.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
}

.loader {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
